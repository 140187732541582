
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {},
  setup() {
    const store = useStore();

    const user = computed(() => {
      return store.getters.currentUser;
    });

    return {
      user,
    };
  },
});
