
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  data() {
    return {
      notifications: [],
    };
  },
  setup() {
    const store = useStore();

    const notificationCount = computed(() => {
      return store.getters.getNotificationCount;
    });

    const data1 = [
      {
        title: "Motor Insurance: SAN/CMV/03412/2017/HQ",
        description:
          "Congratulations! You successfully bought a Motor inurance",
        time: "1 hr",
        icon: "/media/icons/duotone/Clothes/Crown.svg",
        state: "primary",
      },
      {
        title: "Claims Filed: SAN/CMV/03412/2017/HQ",
        description: "Your claim for SAN/CMV/03412/2017/HQ has been received.",
        time: "2 hrs",
        icon: "/media/icons/duotone/Code/Warning-2.svg",
        state: "danger",
      },
      {
        title: "Profile Update",
        description: "You updated your Personal Information.",
        time: "5 hrs",
        icon: "/media/icons/duotone/Communication/Group.svg",
        state: "warning",
      },
    ];

    return {
      data1,
      notificationCount,
    };
  },
  created() {
    // Get notifications
    this.getNotifications();
  },
  methods: {
    getNotifications() {
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.NOTIFICATIONS_ROUTE)
          .then(({ data }) => {
            //Assign data to variables
            this.notifications = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
