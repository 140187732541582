
import { defineComponent, computed } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
// import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import variables from "@/router/api";

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    // KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();
    let route = "";

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const cartCount = computed(() => {
      return store.getters.getCartCount;
    });

    const notificationCount = computed(() => {
      return store.getters.getNotificationCount;
    });

    const avatar = computed(() => {
      return store.getters.getAvatar;
    });

    if (user.value.role == variables.COOPERATIVE_ADMIN_USER_ROLE) {
      route = "/cooperative/cart";
    } else {
      route = "/insurance-packages/cart";
    }
    return {
      user,
      cartCount,
      route,
      notificationCount,
      avatar,
    };
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
  },
});
