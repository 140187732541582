
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import UserMenuIIndividual from "@/components/reusable/UserMenuIIndividual.vue";
// import UserMenuBroker from "@/components/reusable/UserMenuBroker.vue";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import variables from "@/router/api";

export default defineComponent({
  name: "kt-user-menu",
  // components: { UserMenuIIndividual, UserMenuBroker },
  components: { UserMenuIIndividual },
  data() {
    return {
      displayIndividualMenu: false,
      displayBrokerMenu: false,
    };
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const signOut = () => {
      ApiService.setHeader();
      ApiService.post(variables.LOGOUT_ROUTE, "").then((response) => {
        variables.toastAlert(response.data.message, "success");
        store.dispatch(Actions.LOGOUT).then(() => {
          router.push({ name: "sign-in" });
          window.location.reload();
        });
      });
    };

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const avatar = computed(() => {
      return store.getters.getAvatar;
    });

    return {
      signOut,
      user,
      avatar,
    };
  },
  created() {
    const role = window.localStorage.getItem("userRole");
    this.displayIndividualMenu =
      role == variables.INDIVIDUAL_CLIENT_USER_ROLE ? true : false;
    this.displayBrokerMenu = role == variables.BROKER_USER_ROLE ? true : false;
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
  },
});
